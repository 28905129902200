<template>
  <div id="container-options-menu">
    <principalMenuItem v-for="item in items"
      :key="item.title"
      :title="$t(item.title)"
      :description="$t(item.description)"
      :route="item.route"
      :img="item.img"
      :permission="item.permission.indexOf(userRole) >= 0"
    >
    </principalMenuItem>
  </div>
</template>
<script>
import principalMenuItem from '@core/spore-components/principalMenuItem/principalMenuItem.vue'

export default {
  components: {
    principalMenuItem
  },
  data () {
    return {
      userRole: this.$store.state.session.AppActiveUser().userRole.toString(),
      items: [
        /*
        {
          route: '/dashboard',
          img: 'card-text',
          title: 'Tablero',
          description: '',
          permission: ['A', 'C', 'S', 'I']
        },
        {
          route: '/gabinets',
          img: 'energy_label_blue',
          title: 'Gabinetes',
          description: '',
          permission: ['A', 'C', 'S', 'I']
        },
        {
          route: '/meters',
          img: 'kws',
          title: 'Medidores',
          description: '',
          permission: ['A', 'C', 'S', 'I']
        },
        */
        {
          route: '/dashboard/GWH0079',
          img: 'card-text',
          title: 'Tablero GWH00-79',
          description: '',
          permission: ['A', 'C', 'S', 'I']
        },
        {
          route: '/meters/GWH0079',
          img: 'kws',
          title: 'Medidores GWH00-79',
          description: '',
          permission: ['A', 'C', 'S', 'I']
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Menu.scss";
</style>
